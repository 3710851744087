import React, {useState, useEffect, useCallback} from 'react';
import {
    useDataProvider,
    TopToolbar,
    EditButton,
    TextInput,
    Edit,
    SimpleForm,
    ShowButton,
    SimpleShowLayout,
    TextField,
    DateField,
    Show,
    useNotify,
    useRefresh,
    useRedirect,
    useShowController,
    useEditController,
    Toolbar,
    SaveButton,
    useRecordContext,
    Button,
    Confirm,
    SearchInput,
    List,
    Datagrid,
    useListController,
    FunctionField,
    RadioButtonGroupInput,
    ArrayField
} from 'react-admin';

import DeleteIcon from '../assets/icons/DeleteIcon';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../components/Loader';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '../components/Pagination';
import { debounce } from 'lodash';
import { CustomBrandField } from '../fields/CustomBrandField';

const DeviceListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const devicesFilters = [    
    <SearchInput key='deviceNumber' source='deviceNumber' alwaysOn placeholder='By Device Number' style={{ padding:'1rem 0' }} debounce='true' resettable/>,
    <SearchInput key='kvps' source='kvps' alwaysOn placeholder='By KVPS' style={{ padding:'1rem 0' }} debounce='true' resettable/>,
    <SearchInput key='title' source='title' alwaysOn placeholder='By Title' style={{ padding:'1rem 0' }} debounce='true' resettable/>,
    <SearchInput key='countryIso3' source='countryIso3' alwaysOn placeholder='By Country ISO3' style={{ padding:'1rem 0' }} debounce='true' resettable/>,
    <RadioButtonGroupInput sx={{marginBottom:'8px'}} key='deviceClass' source="deviceClass" label='' alwaysOn choices={[
        { id: 'All', name: 'All types' },
        { id: 'CHROMEBOX', name: 'CHROMEBOX' },
        { id: 'AVE', name: 'AVE' },
      ]} />
];

export const DeviceList = () =>{
    const {page, total, setPage } = useListController();
    return (
            <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<DeviceListActions/>} filters={devicesFilters}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source='deviceClass' label='Device Type' />
                    <CustomBrandField source="brand" />
                    <TextField source='deviceNumber' label='Device Number'/>
                    <FunctionField
                        label="Dealership"
                        render={record => record.dealership?.split('/').pop() || 'No Dealership assigned'}
                    /> 
                    <TextField source='title' />
                    <div className='crud_buttons'>
                        <EditButton />
                        <ShowButton />
                    </div>
                </Datagrid>
            </List>
          )
}

const fetchDeviceModuleTypes = async (moduleTypes, dataProvider) => {
    if (!moduleTypes || moduleTypes.length === 0) return [];
    try {
        const results = await Promise.all(
            moduleTypes.map(async (module) => {
                const {data} = await dataProvider.getOne('moduleTypes',{ id: module.split('/').pop()});
                return data;
            })
        );
        return results;
    } catch (error) {
        console.error('Error fetching module types:', error);
        return [];
    }
};

const fetchAllModuleTypes = async (dataProvider) => {
    try {
        const {data} = await dataProvider.getList('moduleTypes', {pagination :{page:1}})
        return data;
    } catch (error) {
        console.error('Error fetching module types:', error);
        return [];
    }
};

export const DeviceEdit = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { isLoading, record } = useEditController();
    const device = record;
    const [searchDealershipsQuery, setSearchDealershipsQuery] = useState('');
    const [searchDealershipsResults, setSearchDealershipsResults] = useState('');
    const [isSearchingDealership, setIsSearchingDealership] = useState(false);
    const [selectedModuleTypes, setSelectedModuleTypes] = useState([]);
    const [availableModuleTypes, setAvailableModuleTypes] = useState([]);
    const [selectedDealership, setSelectedDealership] = useState({});

    useEffect(() => {
        if (device?.moduleTypes) {
            fetchDeviceModuleTypes(device.moduleTypes, dataProvider).then((res)=> setSelectedModuleTypes(res.map(value => {
                return ({
                    id: value['@id'],
                    title: value.title
                })})))
        }
        fetchAllModuleTypes(dataProvider).then((data)=> {
            const typesArray = data.map(value => {
                return ({
                    id: value['@id'],
                    title: value.title
                })});
            setAvailableModuleTypes(typesArray);
        })

        if (device?.dealership) {
            dataProvider.getOne('dealerships', {  id: device.dealership.split('/').pop() })
            .then(({ data }) => {
                setSelectedDealership(data);
            })
            .catch(error => {
                console.error('Error fetching dealership:', error);
            });
        }
    }, [record]);

    const searchDealerships = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingDealership(true)

                const response = await dataProvider.getList('dealerships', {
                    filter: { kvps: query },
                    pagination: { page: 1 },
                });

                setSearchDealershipsResults(response.data)
                setIsSearchingDealership(false)

            } catch (error) {
                console.error('Error fetching users:', error);
                setIsSearchingDealership(false)
            }
        } else {
            setSearchDealershipsResults('');
        }
    };

    const debouncedSearchDealerships = useCallback(
        debounce((query) => {
            searchDealerships(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealerships.cancel();
        };
    }, [debouncedSearchDealerships]);

    if (isLoading) return <Loader/>

    const handleModuleTypeSelect = (moduleTypeId) => {
        const selected = availableModuleTypes.find(moduleType => moduleType.id === moduleTypeId);
        if (selected && !selectedModuleTypes.some(moduleType => moduleType.id === selected.id)) {
            setSelectedModuleTypes([...selectedModuleTypes, selected]);
        }
    };

    const handleModuleTypeRemove = (moduleTypeToRemove) => {
        setSelectedModuleTypes(prevModuleTypes =>
            prevModuleTypes.filter(moduleType => moduleType.id !== moduleTypeToRemove.id)
        );
    };

    const renderModuleTypeDropdown = () => {
        const moduleTypesToDisplay = availableModuleTypes.filter(moduleType =>
            !selectedModuleTypes.some(selectedModule => selectedModule.id === moduleType.id));

        return (
            <select className = 'channels_dropdown' onChange={(e) => handleModuleTypeSelect(e.target.value)}>
                <option value=''>Select a Module Type</option>
                {moduleTypesToDisplay.map(moduleType => (
                    <option className = 'channel' key={moduleType.id} value={moduleType.id}>
                        {moduleType.title}
                    </option>
                ))}
            </select>
        );
    };

    const renderModuleTypes = () => {
        return (
            <div>
                {selectedModuleTypes.map((moduleType, index) => (
                    <div className = 'channel' key={index}>
                        <span className='searched_name_or_title'>
                            {moduleType.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleModuleTypeRemove(moduleType)} ><CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleDealershipSelect = (dealer) => {
        setSelectedDealership(dealer);
        setSearchDealershipsQuery('');
        setSearchDealershipsResults('')
    };

    const renderDealershipsInput = () => {
        return (
                    <>
                        <div className='countries'>
                        {selectedDealership.id && renderDealerships()}
                            <div className='search_container'>
                                <input
                                    className='countries_searchbar'
                                    type='text'
                                    placeholder='Search for dealerships by kvps'
                                    value = {searchDealershipsQuery}
                                    onChange={handleSearchDealershipsChange}
                                />
                                <SearchIcon className='search_icon'/>
                            </div>
                        {Array.isArray(searchDealershipsResults) && !isSearchingDealership &&
                            (searchDealershipsResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchDealershipsResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        )))}
                        {isSearchingDealership && <div>Searching...</div>}
                        </div>
                    </>
        );
    };

    const renderDealerships = () => {
        return (
            <div className = 'channel'>
                    {selectedDealership.title}
            </div>
        );
    };

    const handleSearchDealershipsChange = (event) => {
        const query = event.target.value
        setSearchDealershipsQuery(query);
        debouncedSearchDealerships(query);
    };

    const onSuccess = async (data) => {
        try {
            const updatedDealer = {
                title: data.title,
                description: data.description || '',
                moduleTypes: selectedModuleTypes.map(module => module.id),
                dealership: selectedDealership['@id'],
            }

            await dataProvider.update('devices', {
                id: data.id,
                data: updatedDealer,
                previousData: data
            });

            notify('device data updated successfully', 'info');
            redirect(`/devices/${data.id}/show`);
            refresh();
        } catch (error) {
            console.error('Error updating user', error);
            notify(`Error updating ${error.message}`, 'warning');
        }
    };

    const CustomDeleteButton = () => {
        const record = useRecordContext();
        const [open, setOpen] = useState(false);
        const dataProvider = useDataProvider();
        const notify = useNotify();
        const redirect = useRedirect();
    
        const handleDelete = async () => {
            setOpen(false);
            try {
                await dataProvider.delete('devices', { id: record.id });
                notify('Dvice deleted successfully', 'info');
                redirect('/devices');
            } catch (error) {
                notify(`Error deleting Device: ${error.message}`, 'warning');
            }
        };
        return (
            <>
                <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px',color:'red'}}>
                    <DeleteIcon />
                </Button>
    
                <Confirm
                    isOpen={open}
                    loading={false}
                    title="Are you sure you want to delete this device?"
                    content="This action is irreversible."
                    onConfirm={handleDelete}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    };
    
    const DeviceEditToolbar = () => {
        const isAdmin = localStorage.getItem('roles').includes('NEWRETAIL_ADMIN');
        return (
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
            <SaveButton  alwaysEnable type="button" mutationOptions={{ onSuccess }} />
            {isAdmin && <CustomDeleteButton />}
        </Toolbar>
    )};

    return (
        <Edit {...props} title='Device Edit'>
            <SimpleForm toolbar={<DeviceEditToolbar />}>
                <TextInput fullWidth source='title' label='Title'/>
                <TextInput fullWidth source='description' label ='Description' />
                <h3 className='channels_title ' >Module Types:</h3>
                <div className='channels'>
                    {renderModuleTypes()}
                    {renderModuleTypeDropdown()}
                </div>
                <h3 className='channels_title' >Dealership:</h3>
                {renderDealershipsInput()}
            </SimpleForm>
        </Edit>
    )
}

export const DeviceShow = () => {
    const { record } = useShowController();
    const dataProvider = useDataProvider();
    const [modules, setModules] = useState([]);

    useEffect(() => {
        if (record?.moduleTypes) {
            fetchDeviceModuleTypes(record.moduleTypes, dataProvider).then(setModules);
        }
    }, [record]);

    return <Show>
            <SimpleShowLayout className = 'simpleShowLayout'>
                <TextField source = 'deviceClass'/>
                <TextField source = 'brand'/>
                <TextField source = 'deviceNumber'/>
                <FunctionField
                        label="Dealership"
                        render={record => record.dealership?.split('/').pop() || 'No Dealership assigned'}
                    /> 
                <TextField source = 'title'/>
                <TextField source = 'description'/>
                <FunctionField
                    label='Module Types'
                    render={() =>
                        modules.length > 0
                            ? modules.map(module => module.title).join(', ')
                            : 'No module types available'
                    }
                />
                <ArrayField source='playlists'>
                    <FunctionField
                        label="Playlists"
                        render={record => record.playlists.length > 0 ? record.playlists.map(playlist => playlist.title) : 'No playlists available'}
                    />                    
                </ArrayField>
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </SimpleShowLayout>
        </Show>
}
