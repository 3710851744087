import React, {useState} from 'react';
import {
    useDataProvider,
    TopToolbar,
    CreateButton,
    TextInput,
    SimpleForm,
    Edit,
    ShowButton,
    EditButton,
    BooleanInput,
    useRedirect,
    useNotify,
    NumberInput,
    SimpleShowLayout,
    Show,
    TextField,
    Create,
    SaveButton,
    Toolbar,
    Button,
    Confirm,
    useRefresh,
    FunctionField,
    useListController,
    useShowController,
    useRecordContext,
    List,
    Datagrid,
    BooleanField,
    SelectInput,
}from 'react-admin';
import NotFound from '../components/Profile/NotFound/NotFound';
import DeleteIcon from '../assets/icons/DeleteIcon';
import { CustomBrandField } from '../fields/CustomBrandField';
import Pagination from '../components/Pagination';

const languages = [{id:'en',name: 'English'}, {id:'de',name: 'German'}]

const FaqListActions = () => (
    <TopToolbar>
        <CreateButton label='Create' />
    </TopToolbar>
);

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('faqs', { id: record.id });
            notify('Faq deleted successfully', 'info');
            redirect('/faqs');
            refresh();
        } catch (error) {
            notify(`Error deleting resource: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style={{marginLeft:'10px'}}>
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this Question?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const Empty = () => (
    <div style={{width:'100%'}}>
        <FaqListActions/>
        <p>
            Do you need assistance in technical, editorial or administrative cases regarding Retail Content Manager?!
            <br/>
            Please have a look on our FAQs, handouts and video tutorials for general information.
        </p>
        <NotFound resource='faqs' />
    </div>
);

export const FaqList = () => {
    const {page, total, setPage } = useListController();
    return (
        <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<FaqListActions/>} empty={<Empty />}>
            <Datagrid bulkActionButtons={false}>
                <CustomBrandField source="brand" />
                <TextField source='category'/>
                <TextField source='position'/>
                <FunctionField label='Language' render={record => `${languages.filter(lang => lang.id === record.language)[0].name}`} />
                <BooleanField source='enabled' label='Show'/>
                <FunctionField label="Channel" render={record => `${record.channel.split('/').pop()}`} />
                <TextField source='question'/>
                <TextField source='answer'/>
                <span className='crud_buttons'>
                    <EditButton className='edit-button' />
                    <ShowButton className='show-button' />
                </span>
            </Datagrid>
        </List>)
}

const CustomToolbar = props => {
    const isAdmin = localStorage.getItem('roles').includes('NEWRETAIL_ADMIN');
    return (
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'space-between'}}>
            <SaveButton />
            {isAdmin && <CustomDeleteButton />}
        </Toolbar>
)};

export const FaqEdit = () => { 
    const {record} = useShowController();   
    return (
        <Edit redirect='show' title={record.question}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <SelectInput source='language' choices={languages} fullWidth required/>
                <TextInput fullWidth source='category' label='Category' />
                <NumberInput source='position' label='Position' fullWidth />
                <TextInput fullWidth source='question' label='Question' multiline />
                <TextInput fullWidth source='answer' label='Answer' multiline />
                <BooleanInput fullWidth source='enabled' label='Show' />
            </SimpleForm>
        </Edit>
)}

export const FaqShow = () => {
    const {record} = useShowController();   
        return (<Show  title={record.question}>
            <SimpleShowLayout className='simpleShowLayout'>
                <CustomBrandField source="brand" />
                <FunctionField label='Language' render={record => `${languages.filter(lang => lang.id === record.language)[0].name}`} />
                <TextField source ='category' />
                <FunctionField label='Channel' render={record => `${record.channel.split('/').pop()}`} />
                <TextField source='position' />
                <TextField source='question' />
                <TextField source='answer' />
                <BooleanField source='enabled' label='Show'/>
            </SimpleShowLayout>
        </Show>)}


export const FaqCreate = () => (
        <Create redirect='show'>
            <SimpleForm>
                <SelectInput source='language' choices={languages} fullWidth required/>
                <TextInput source='category' fullWidth required placeholder='Category' resettable/>
                <NumberInput source='position' fullWidth required/>
                <TextInput source='question' fullWidth required placeholder='Question' rows={3} resettable multiline/>
                <TextInput source='answer' fullWidth required placeholder='Answer' rows={3} resettable multiline/>
                <BooleanInput label='Show' source='enabled' />
            </SimpleForm>
        </Create>
);