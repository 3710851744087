import queryString from 'query-string';
import {store} from '../../store';

class DeviceEndpointResolver {
    supports(resource) {
        return resource === 'devices';
    }

    getListUri (_resource, params) {
        const state = store.getState();
        const queryParams = {
            page: params.pagination.page,
            ...(params.filter.deviceClass && params.filter.deviceClass !== 'All' && { deviceClass: params.filter.deviceClass }),
            ...(params.filter.countryIso3 && { countryIso3: params.filter.countryIso3.toUpperCase() }),
            ...(params.filter.kvps && { kvps: params.filter.kvps }),
            ...(params.filter.title && { title: params.filter.title }),
            ...(params.filter.deviceNumber && { deviceNumber: params.filter.deviceNumber }),
        }
        return `/brands/${state.brand.brand}/devices?${queryString.stringify(queryParams)}`
    }

    getOneUri(_resource, params){
        const state = store.getState();
        return `/brands/${state.brand.brand}/devices/${params.id}`
    }

    getUpdateUri(_resource, params) {
        const state = store.getState();
        return `/brands/${state.brand.brand}/devices/${params.id}`;
    }

    getDeleteUri(_resource, params){
        const state = store.getState();
        return `/brands/${state.brand.brand}/devices/${params.id}`;
    }
}

export default DeviceEndpointResolver;