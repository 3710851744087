import React from 'react';
import { useRecordContext } from 'react-admin';

export const CustomImageField = (props) => {
    const { source } = props;
    const record = useRecordContext();
    if (!record || !source) return null;
    return (
        <img
            src={record[source]}
            alt="Car Model"
            style={{ width: 300, height: 'auto', borderRadius: '5px' }}
        />
    );
};