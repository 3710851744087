import { AuthCodeFlowPkce } from '@oneaudi/avp-classic-abi-authcode-pkce-js';
const authorizeEndpoint = process.env.REACT_APP_AUTHORIZE_ENDPOINT;
const tokenEndpoint=process.env.REACT_APP_TOKEN_ENDPOINT;
const logoutpoint=process.env.REACT_APP_LOGOUT_ENDPOINT;
const clientId=process.env.REACT_APP_CLIENT_ID
const redirectUri = `${window.location.origin}/connect`;
const logoutRedirectUri = `${window.location.origin}/login`;

export const auth = new AuthCodeFlowPkce({
    authorizeEndpoint: authorizeEndpoint,
    tokenEndpoint: tokenEndpoint,
    clientId: clientId,
    redirectUri: redirectUri,
    logoutEndpoint: logoutpoint,
    logoutRedirectUri: logoutRedirectUri,
    scope: 'openid'
  });
