import queryString from 'query-string';
import {store} from '../../store';

class DealershipGroupsEndpointResolver {
    supports(resource) {
        return resource === 'dealershipGroups';
    }

    getOneUri(_resource, params) {
        const state = store.getState();
        return params.meta ? params.meta.id : `/brands/${state.brand.brand}/dealership-groups/${params.id}`;
    }


    getListUri = (_resource, params) => {
        const state = store.getState();
        const {filter, pagination} = params;
        const queryParams = {
            ...(filter.title && { title: filter.title }),
            ...(filter.kvps && {'dealerships.kvps': filter.kvps.toUpperCase()}),
            ...(filter.country && {'countries.iso3': filter.country.toUpperCase()}),
            page: pagination.page,
        }
        return `/brands/${state.brand.brand}/dealership-groups?${queryString.stringify(queryParams)}`;
    };

    getUpdateUri = (_resource, params) => {
        return params.data['@id'];
    }

    getCreateUri = () => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/dealership-groups`;
    }

    getDeleteUri = (_resource, params) => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/dealership-groups/${params.id}`;
    }
}

export default DealershipGroupsEndpointResolver;