import React, {useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    CreateButton,
    TopToolbar,
    SimpleForm,
    Create,
    TextInput,
    EditButton,
    Edit,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceArrayField,
    BooleanField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    SearchInput,
    Toolbar,
    SaveButton,
    Confirm,
    useRedirect,
    Button,
    useNotify,
    useRefresh,
    useDataProvider,
    useShowController,
    useListController
} from 'react-admin';
import DeleteIcon from '../assets/icons/DeleteIcon';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';

const rolesFilters = [
    <SearchInput key='name' source='name' alwaysOn placeholder='By Role Name'style={{padding:'1rem 0'}}/>
];

const RoleListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const RoleEditButtonWithVisibility = () => {
    const record = useRecordContext();
    const shouldRenderEditButton = record && record.editable;
    return shouldRenderEditButton ? <EditButton /> : null;
};

export const RolesList = () => { 
    const {page, total, setPage } = useListController();
    return (
            <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<RoleListActions/>} filters={rolesFilters}>
            <Datagrid bulkActionButtons={false}>
                <TextField source='name' sortable={false} />
                <TextField source='description' sortable={false} />
                <BooleanField source='editable' sortable={false} />
                <ReferenceArrayField label='Permissions' reference='permissions' source='permissions' sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source='label' sortable={false} />
                    </SingleFieldList>
                </ReferenceArrayField>
                <span className='crud_buttons'>
                    <RoleEditButtonWithVisibility className='edit-button'/>
                    <CustomDeleteButton  className='show-button'/>
                </span>
            </Datagrid>
        </List>
    )}


export const RoleCreate = () => (
    <Create redirect='show'>
        <SimpleForm>
            <TextInput source='name' fullWidth required />
            <TextInput source='description' fullWidth multiline required />
            <BooleanInput source='editable' />
            <ReferenceArrayInput source='permissions' reference='permissions' required>
            <AutocompleteArrayInput
                fullWidth
                filterToQuery={(q) => ({value: q})}
                optionText='value'
                required
            />
          </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('roles', { id: record.id });
            notify('Resource deleted successfully', 'info');
            redirect('/roles');
            refresh();
        } catch (error) {
            notify(`Error deleting resource: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px'}}>
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this item?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};


const CustomToolbar = props => (
    <Toolbar style={{display:'flex', justifyContent:'space-between'}} {...props} >
        <SaveButton />
    </Toolbar>
);

export const RoleEdit = () => {
    const { record } = useShowController();
    if (!record) return <Loader/>;
    return(
        <Edit>
            <SimpleForm toolbar={<CustomToolbar/>} >
                <TextInput source='name' fullWidth required />
                <TextInput source='description' fullWidth multiline required/>
                <BooleanInput source='editable' />
                <ReferenceArrayInput source='permissions' reference='permissions' required>
                <AutocompleteArrayInput
                    fullWidth
                    filterToQuery={(q) => ({value: q})}
                    optionText='value'
                    required
                />
            </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
)};
