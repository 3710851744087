import * as React from 'react';
import { forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { removeCookie } from './helpers';
import { auth } from './GRP';

const MyLogoutButton = forwardRef((props, ref) => {
    const handleClick = () => {
        removeCookie('token');
        removeCookie('refreshToken');
        auth.logout();
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ExitIcon /> Logout
        </MenuItem>
    );
});

MyLogoutButton.displayName = 'MyLogoutButto';

export default MyLogoutButton;