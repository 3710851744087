import React from 'react';
import {
    CreateButton,
    TopToolbar,
    TextInput,
    SimpleForm,
    Edit,
    SaveButton,
    Toolbar,
    List,
    Show,
    TextField,
    BooleanInput,
    BooleanField,
    SimpleShowLayout,
    useRecordContext,
    Create,
    Datagrid,
    EditButton,
    ShowButton,
    useShowController,
    useListController
} from 'react-admin';
import { BRAND_DETAILS } from './Brands';
import Loader from '../components/Loader.js';
import Pagination from '../components/Pagination';

const CustomToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
);

const ChannelListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);


const CustomBrandField = ({ source }) => {
    const record = useRecordContext();
    if (!record || !source) return null;
    const brandCode = record[source]?.split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand.name}</span>;
};

export const ChannelList = () => { 
    const {page, total, setPage } = useListController();
    return (
        <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<ChannelListActions/>}>
            <Datagrid bulkActionButtons={false}>
                <CustomBrandField source="brand" />
                <TextField source='title'/>
                <TextField source='slug'/>
                <BooleanField source='countryAware'/>
                <span className='crud_buttons'>
                    <EditButton className='edit-button'/>
                    <ShowButton className='show-button'/>
                </span>
            </Datagrid>
        </List>
)};


export const ChannelEdit = () => {
    const { record } = useShowController();
    if (!record) return <Loader/>;
    return (
        <Edit redirect='show'>
            <SimpleForm toolbar={<CustomToolbar/>} >
                <TextInput source='title' required/>
                <BooleanInput source='countryAware' />
            </SimpleForm>
        </Edit>
)};


export const ChannelShow = () => {
    const { record } = useShowController();
    if (!record) return <Loader/>;
    return(
        <Show>
            <SimpleShowLayout>
                <TextField source='title' />
                <CustomBrandField source="brand" />
                <TextField source='slug' />
                <BooleanField source='countryAware' />
            </SimpleShowLayout>
        </Show>
)}

export const ChannelCreate = () =>(
    <Create redirect='show'>
        <SimpleForm >
            <TextInput source='title' required/>
            <BooleanInput source='countryAware' />
        </SimpleForm>
    </Create>
);