import React, { useEffect, useState, useCallback} from 'react';
import {
    useDataProvider,
    TopToolbar,
    SimpleShowLayout,
    Show,
    TextField,
    ShowButton,
    useRecordContext,
    TextInput,
    Edit,
    SimpleForm,
    useNotify,
    useRedirect,
    Button,
    EditButton,
    useShowController,
    useListController,
    CreateButton,
    Create,
    FormDataConsumer,
    Toolbar,
    Confirm,
    List,
    Datagrid,
    SearchInput,
    FunctionField,
} from 'react-admin';
import { CustomBrandField } from '../fields/CustomBrandField';
import Loader from '../components/Loader.js';
import { debounce } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '../assets/icons/DeleteIcon';
import {useParams} from 'react-router-dom';
import Pagination from '../components/Pagination';

const dealersFilters = [
    <SearchInput key='kvps' source='kvps' alwaysOn placeholder='By KVPS' style={{ padding:'1rem 0' }}/>
];

const DealershipListActions = () => {
    const isAdmin = localStorage.getItem('roles').includes('NEWRETAIL_ADMIN');
    return (
    <TopToolbar>
       {isAdmin && <CreateButton label = 'Create' />}
    </TopToolbar>
)};

export const DealershipsList = () =>{
    const {page, total, setPage } = useListController();
    return (
            <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<DealershipListActions/>} filters={dealersFilters}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source='kvps' />
                    <TextField source='title' />
                    <FunctionField
                            label="Address"
                            render={record => `${record.address.street}, ${record.address.zipCode}`}
                    />
                    <span className='crud_buttons'>
                        <EditButton />
                        <ShowButton />
                    </span>
                </Datagrid>
            </List>
          )
}

const AddressField = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    return (
        <div>
            {Object.entries(record[source])
                .filter(([key]) => key !== '@type' && key !== '@id')
                .map(([key, value], index) => (
                    <div key={index}>
                        {key}: {value}
                    </div>
                ))}
        </div>
    );
};


export const DealershipsShow = () => {
    const { isLoading, record } = useShowController();
    if (isLoading) return <Loader />;
    return (
        <Show title = {record.id}>
            <SimpleShowLayout>
                <TextField source='title'/>
                <TextField source='kvps'/>
                <CustomBrandField source="brand" />
                <AddressField source='address' label='Address'/>
            </SimpleShowLayout>
        </Show>
    )
};


export const DealershipCreate = () => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [searchCountryResults, setSearchCountryResults] = useState('');
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [isSearchingCountry, setIsSearchingCountry] = useState(false);

    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();

    useEffect(()=>{
        searchCountry('');
    },[dataProvider])

    const debouncedSearchCountry = useCallback(
        debounce((query) => {
            searchCountry(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchCountry.cancel();
        };
    }, [debouncedSearchCountry]);

    const handleCountryRemove = () => {
        setSelectedCountry('');
    };
    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        setSearchCountryResults('');
        setSearchCountryQuery('');
    };

    const handleSearchCountryChange = (event) => {
        const query = event.target.value
        setSearchCountryQuery(query);
        debouncedSearchCountry(query)

    };

    const searchCountry = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingCountry(true)
                const response = await dataProvider.getList('countries', {
                    filter: { name: query },
                    pagination: { page: 1},
                    sort: { field: 'iso3', order: 'ASC' }
                });

                setSearchCountryResults(response.data)
                setIsSearchingCountry(false)
            } catch (error) {
                console.error('Error fetching countries:', error);
                setIsSearchingCountry(false)
            }
        } else {
            setSearchCountryResults('');
        }
    };

    const renderCountry = () => {
        if(selectedCountry) { return (
                <div className='country'>
                    <span className='searched_name_or_title'>
                        {selectedCountry.name}
                    </span>
                    <button className='remove_button' type='button'
                        onClick={() => handleCountryRemove(selectedCountry)}>
                        <CloseIcon  />
                    </button>
                </div>
        )} else return null;
    };

    const CustomToolbar = () => (
        <TopToolbar>
        </TopToolbar>
    );

    return (
        <Create>
                <SimpleForm toolbar = {<CustomToolbar/>}>
                    <TextInput fullWidth source='kvps' required/>
                    <TextInput fullWidth source='title' required/>
                    {/* search country */}
                    <div className='countries' style={{marginBottom:'20px'}}>
                        <h3 style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontFamily: 'AudiTypeScreenNormal',
                                fontWeight: 400,
                                fontSize: '1.0714285714285714rem',
                                lineHeight: '1.4375em',
                                marginLeft:0
                            }}
                            className='countries_title'>
                            Country *
                        </h3>
                        {renderCountry()}
                        <div className='search_container'>
                            <input
                                style={{marginTop:'5px'}}
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for country'
                                value = {searchCountryQuery}
                                onChange={handleSearchCountryChange}
                            />
                            <SearchIcon style={{marginTop:'7px'}} className='search_icon'/>
                        </div>
                        {Array.isArray(searchCountryResults) && !isSearchingCountry &&
                            (searchCountryResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchCountryResults.map(country => (
                            <div className = 'search-result-options' key={country.id} onClick={() => handleCountrySelect(country)}>
                                {country.name}
                            </div>
                        )))}
                        {isSearchingCountry && <div>Searching...</div>}
                    </div>
                    <TextInput fullWidth source='address.street' label='Street' required/>
                    <TextInput fullWidth source='address.city' label='City' required/>
                    <TextInput fullWidth source='address.zipCode' label='Zip Code' required/>
                    <TextInput fullWidth source='address.state' label='State' required/>
                    <FormDataConsumer>
                        {({ formData }) => {
                            const handleCustomSave = async () => {
                                formData.country = selectedCountry['@id'];

                                try {
                                    if(!formData.kvps) { throw new Error('The Kvps field is mandatory and must be filled out.') }
                                    if(!formData.title) { throw new Error('The Title field is mandatory and must be filled out.') }
                                    if(!formData.country) { throw new Error('The Country field is mandatory and must be filled out.') }
                                    if(!formData.address.street) { throw new Error('The Street field is mandatory and must be filled out.') }
                                    if(!formData.address.city) { throw new Error('The City field is mandatory and must be filled out.') }
                                    if(!formData.address.zipCode) { throw new Error('The Zip code field is mandatory and must be filled out.') }
                                    if(!formData.address.state) { throw new Error('The State field is mandatory and must be filled out.') }

                                    await dataProvider.create('dealerships', { data: formData}).then((res)=>{
                                        notify('Dealership created successfully', 'info');
                                        redirect(`/dealerships/${res.data.id}/show`);                                   
                                     })
                                } catch (error) {
                                    console.error('Error creating dealership:', error);
                                    notify(`Error creating dealership: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative', top:'10px', zIndex:'99'}} className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleForm>
        </Create>
   )
}

export const DealershipsEdit = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const {id} = useParams();

    const CustomDeleteButton = () => {
        const [open, setOpen] = useState(false);
        const handleDelete = async () => {
            setOpen(false);
            try {
                await dataProvider.delete('dealerships', { id });
                notify('dealerships deleted successfully', 'info');
                redirect('/dealerships');
            } catch (error) {
                notify(`Error deleting resource: ${error.message}`, 'warning');
            }
        };

        return (
            <>
                <Button label='Delete' onClick={() => setOpen(true)} style = {{marginLeft:'10px'}}>
                    <DeleteIcon />
                </Button>
                <Confirm
                    content='Are you sure you want to delete this dealer?'
                    isOpen={open}
                    loading={false}
                    title=''
                    onConfirm={handleDelete}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    };

    const CustomToolbar = () => {
        return (
            <Toolbar style={{display:'flex', justifyContent:'space-between'}}>
                <CustomDeleteButton />
            </Toolbar>
        );
    };

    return (
        <Edit>
                <SimpleForm toolbar = {<CustomToolbar/>}>
                    <TextInput fullWidth source='title' required/>
                    <TextInput fullWidth source='address.street' label='Street' required/>
                    <TextInput fullWidth source='address.city' label='City' required/>
                    <TextInput fullWidth source='address.zipCode' label='Zip Code' required/>
                    <TextInput fullWidth source='address.state' label='State' required/>
                    <TextInput fullWidth source='owner'/>
                    <FormDataConsumer>
                        {({ formData }) => {

                            const handleCustomSave = async () => {
                                try {
                                    if(!formData.title) { throw new Error('The Title field is mandatory and must be filled out.') }
                                    if(!formData.address.street) { throw new Error('The Street field is mandatory and must be filled out.') }
                                    if(!formData.address.city) { throw new Error('The City field is mandatory and must be filled out.') }
                                    if(!formData.address.zipCode) { throw new Error('The Zip code field is mandatory and must be filled out.') }
                                    if(!formData.address.state) { throw new Error('The State field is mandatory and must be filled out.') }

                                    await dataProvider.update('dealerships', { data: formData})
                                    notify('Dealership updated successfully', 'info');
                                    redirect('/dealerships');
                                } catch (error) {
                                    console.error('Error updateing dealership:', error);
                                    notify(`Error updating dealership: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative',left:'25px'}} className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleForm>
        </Edit>
    )
}

export const DealershipsDelete = ({ record }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleDelete = async () => {
        try {
            await dataProvider.delete('dealerships', { id: record.id });
            notify('Dealership deleted successfully', 'info');
            redirect('/dealerships');
        } catch (error) {
            notify(`Error deleting dealership: ${error.message}`, 'warning');
            console.error('Error deleting dealership:', error);
        }
    };
    return <Button onClick={handleDelete}>Delete</Button>;
};