import {
    useDataProvider,
    TextField,
    NumberField,
    TextInput,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    Show,
    SimpleShowLayout,
    EditButton,
    ShowButton,
    CreateButton,
    TopToolbar,
    SelectInput,
    FunctionField,
    Toolbar,
    SaveButton,
    useNotify,
    useRedirect,
    required,
    Confirm,
    useRecordContext,
    Button,
    List,
    Datagrid,
    useListController,
    useShowController
} from 'react-admin';
import React, { useState, useEffect } from 'react';
import { CustomImageField } from '../fields/CustomImageField';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import DeleteIcon from '../assets/icons/DeleteIcon';
import { CustomBrandField } from '../fields/CustomBrandField';
import Chip from '@mui/material/Chip';

const CarTypeListActions = () => (
    <TopToolbar>
        <CreateButton label='Create' />
    </TopToolbar>
);

export const CarTypeList = () => { 
    const {page, total, setPage } = useListController();
    return (
        <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<CarTypeListActions/>}>
        <Datagrid bulkActionButtons={false}>
            <CustomBrandField source="brand" />
            <TextField source='type'/>
            <CustomImageField source='imageUrl' label='Image' />
            <TextField source='position'/>
            <span className='crud_buttons'>
                <EditButton className='edit-button'/>
                <ShowButton className='show-button'/>
            </span>
        </Datagrid>
    </List>
)};

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('carTypes', { id: record['@id'] });
            notify('Type deleted successfully', 'info');
            redirect('/carTypes');
        } catch (error) {
            notify(`Error deleting Type: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px',color:'red'}}>
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this type?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const CustomToolbar = props => {
    const isAdmin = localStorage.getItem('roles').includes('NEWRETAIL_ADMIN');

    return (
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'space-between'}}>
            <SaveButton />
            {isAdmin && <CustomDeleteButton />}
        </Toolbar>
)};

export const CarTypeEdit = () => {
    const {record} = useShowController();   
    const dataProvider = useDataProvider();

    const [models, setModels] = useState('');
    const [loading, setLoading] = useState(true);
    const fetchModels = async()=> {
        const {data} = await dataProvider.getList('carModels', {
            pagination: { page: 1},
        });
        setModels(data)
        setLoading(false)
    } 

    useEffect(() => {
        fetchModels();
    }, []);
    
    if (loading) return <Loader />

    return(
        <Edit  redirect='show' title={record.type}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source='type' fullWidth validate={[required()]}  />
                <TextInput source='imageUrl' fullWidth multiline validate={[required()]} />
                <NumberInput source='position' fullWidth validate={[required()]} />
                <SelectInput
                        source='model'
                        label='Car Model'
                        choices={models}
                        optionText='model'
                        optionValue='@id'
                        fullWidth
                        validate={[required()]}
                    />
            </SimpleForm>
        </Edit>
    )
};

export const CarTypeCreate = () => {
    const dataProvider = useDataProvider();
    const [models, setModels] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchModels = async()=> {
        const {data} = await dataProvider.getList('carModels', {
            pagination: { page: 1},
        });
        setModels(data)
        setLoading(false)
    } 

    useEffect(() => {
        fetchModels();
    }, []);

    if (loading) return <Loader />

    return (
        <Create redirect='show'>
            <SimpleForm>
                <TextInput source='type' label='Type (example: limousine)' fullWidth validate={[required()]} />
                <TextInput source='imageUrl' label='Image URL (must not be empty)' fullWidth multiline validate={[required()]} />
                <NumberInput source='position' fullWidth validate={[required()]} />
                <SelectInput
                    source='model'
                    label='Car Model'
                    choices={models}
                    optionText='model'
                    optionValue='@id'
                    fullWidth
                />
            </SimpleForm>
        </Create>
    );
};

export const CarTypeShow = () => {
    const {record} = useShowController();   
    const dataProvider = useDataProvider();
    const [model, setModel] = useState('');

    const fetchModel = async()=> {
        const {data} = await dataProvider.getOne('carModels', { id: record.model.split('/').pop()});
        setModel(data)
    } 

    useEffect(() => {
        record && fetchModel();
    }, [record]);

    if (!record) return <Loader/>;
    return (
        <Show title={record.type}>
            <SimpleShowLayout >
                <CustomBrandField source="brand" />
                <TextField source='type'/>
                <CustomImageField source='imageUrl' label='Image' />
                <NumberField source='position' label='Position' />
                <FunctionField label='Model' render={()=> <Chip key={model.id} label={model.model} />} />
            </SimpleShowLayout>
        </Show>
    );
};