import React, {useState, useEffect } from 'react';
import { AppBar, UserMenu } from 'react-admin';
import { Typography } from '@mui/material';
import MyLogoutButton from './MyLogoutButton';
import ProfileButton from './components/Profile/ProfileButton';
import { BRAND_DETAILS } from './pages/Brands';
import BrandSwitcher from './components/BrandSwitcher';
import { setBrand } from './actions/brandSwitcherActions';
import {useDispatch} from 'react-redux';

const MyUserMenu = () => <UserMenu>
        <MyLogoutButton />
        <ProfileButton/>
    </UserMenu>;

const MyAppBar = (props) =>{ 
    const [brandsToSwitch, setUserBrandToSwitch] = useState([]);
    const dispatch = useDispatch();

    useEffect(()=>{
        const userBrands= localStorage.getItem('userBrands');
        dispatch(setBrand(localStorage.getItem('selectedBrand') || userBrands[0]))
        setUserBrandToSwitch(BRAND_DETAILS.filter(brand => userBrands.includes(brand.code)));
    },[])

    return (
    <AppBar
        toolbar={<></>}
        sx={{
            '& .RaAppBar-title': {
                flex: 1,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
        }}
        userMenu={<MyUserMenu/>}
        {...props}
    >
        <Typography
            variant='h6'
            color='inherit'
            className=''
            id='react-admin-title'
            sx={{
                flex: 1
            }}
        />
        {brandsToSwitch.length > 1 && <BrandSwitcher brands={brandsToSwitch} />}
    </AppBar>
)};

export default MyAppBar;