import './MyLoginPage.css';
import { useNotify } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from './GRP';
import { removeCookie } from './helpers';

const MyLoginPage = () => {
    const location = useLocation()
    const notify = useNotify();
    const navigate = useNavigate();
    const handleClickLogin = () => navigate('/connect');
    const handleClickLogout = () =>{ 
        window.history.replaceState({}, '');
        removeCookie('token');
        removeCookie('refreshToken');
        auth.logout();
    }

    if(location.state?.error === 'DEALER role only'){
        notify('Oops! It seems you don’t have permission to access Admin section. If this needs to be reviewed, please contact our support team for further assistance.');
    }

    return (
        <div className='login_div'>
            <div className='login_page_title'>Admin Retail Content Manager<span className='login_page_version'> {process.env.REACT_APP_VERSION || 'x.x.x'} - Internal System</span></div>
            <button
                className='login_button'
                onClick={location.state?.error === 'DEALER role only' ? handleClickLogout : handleClickLogin }
            >
                {location.state?.error === 'DEALER role only' ? 'Logout' : 'Login' }
            </button>
        </div>
    );
};
export default MyLoginPage;