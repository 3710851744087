import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

 const NotFound = () => {
    const adminPages =['brands', 'users', 'roles', 'permissions', 'carModels', 'carTypes', 'moduleTypes','channels', 'devices', 'dealerships', 'dealershipGroups', 'assetOverrides', 'faqs']
    const [pagePath, setPagePath] = useState('');
    const { pathname } = useLocation();

    useEffect(()=>{
        setPagePath(pathname.split('/').pop())
    },[pathname]);

    return adminPages.includes(pagePath)
    ? (<div className='not-found' >
      <h1 className='not-found-message'>Oops! You do not have permission to access {pagePath} resource</h1>
    </div>) 
    : (<div className='not-found' >
            <h1 className='not-found-message'>Not Found!<br/>Either you typed a wrong URL, or you followed a bad link..</h1>
        </div>)
}

export default NotFound