import React from 'react';
import { useRecordContext } from 'react-admin';
import { BRAND_DETAILS } from '../pages/Brands';

export const CustomBrandField = ({ source }) => {
    const record = useRecordContext();
    if (!record || !source) return null;
    const brandCode = record[source]?.split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand.name}</span>;
};