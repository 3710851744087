import React, {useEffect, useState} from 'react';
import './styles/Custom.css';
import {
    Edit,
    SimpleForm,
    TextInput,
    Create,
    NumberInput,
    useDataProvider,
    ShowButton,
    SimpleShowLayout,
    Show,
    TextField,
    NumberField,
    ArrayField,
    CreateButton,
    TopToolbar,
    SaveButton,
    Toolbar,
    useNotify,
    useRedirect,
    useRecordContext,
    EditButton,
    Button,
    Confirm,
    List,
    Datagrid,
    useShowController,
    useListController
} from 'react-admin';
import Loader from '../components/Loader';
import {CustomImageField} from '../fields/CustomImageField';
import DeleteIcon from '../assets/icons/DeleteIcon';
import { CustomBrandField } from '../fields/CustomBrandField';
import Pagination from '../components/Pagination';
import Chip from '@mui/material/Chip';

const CarModelListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export const CarModelList = () => { 
    const {page, total, setPage } = useListController();
    return (
        <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<CarModelListActions/>}>
            <Datagrid bulkActionButtons={false}>
                <CustomBrandField source="brand" />
                <TextField source='model'/>
                <CustomImageField source='imageUrl' label='Image' />
                <TextField source='position'/>
                <span className='crud_buttons'>
                    <EditButton className='edit-button'/>
                    <ShowButton className='show-button'/>
                </span>
        </Datagrid>
        </List>
)};

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('carModels', { id: record.id });
            notify('Model deleted successfully', 'info');
            redirect('/carModels');
        } catch (error) {
            notify(`Error deleting Model: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px',color:'red'}}>
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this model?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};


const CustomToolbar = props => {
    const isAdmin = localStorage.getItem('roles').includes('NEWRETAIL_ADMIN');
    return (
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'space-between'}}>
            <SaveButton />
            {isAdmin && <CustomDeleteButton />}
        </Toolbar>
)};

export const CarModelEdit = () => {
    const { record } = useShowController();
    if (!record) return <Loader/>;
    return (
        <Edit title={record.model} redirect='show'>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source='model' fullWidth required/>
                <TextInput source='imageUrl' fullWidth multiline required/>
                <NumberInput source='position' fullWidth required/>
            </SimpleForm>
        </Edit>
)};


export const CarModelCreate = () => (
    <Create redirect='show'>
        <SimpleForm>
            <TextInput source='model' label='Model (example: A4)' fullWidth required />
            <TextInput source='imageUrl' fullWidth multiline required />
            <NumberInput source='position' fullWidth required />
        </SimpleForm>
    </Create>
);

const ShowListOfCarTypes = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [carTypes, setCarTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCarTypes = async () => {
            if (record && record.carTypes) {
                try{
                    const fetchedCarTypes = await Promise.all(
                        record.carTypes.map(async url => {
                            const carTypeId = url.split('/').pop();
                            const { data } = await dataProvider.getOne('carTypes', {id: url});
                            return { id: carTypeId, type: data.type };
                        })
                    );
                    setCarTypes(fetchedCarTypes);
                }
                catch (error) {
                    setError(error);
                }
                finally{
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchCarTypes();
    }, [record, dataProvider]);

    if (loading) {
        return <Loader/>;
    }
    if (error) {
        return <div>Error loading car types: {error.message}</div>;
    }
    return carTypes.map(carType => <Chip key={carType.id} label={carType.type} sx={{margin: '4px'}}/>)
};

const CarModelShowActions = () => (
    <TopToolbar>
        <EditButton />
    </TopToolbar>
);

export const CarModelShow = () => {
    const { record } = useShowController();
    if (!record) return <Loader/>;
    return (
        <Show title={record.model} actions={<CarModelShowActions/>}>
            <SimpleShowLayout>
                <CustomBrandField source='brand'/>
                <TextField source='model'/>
                <CustomImageField source='imageUrl' label='Image' style={{ width: '400px'}} />
                <NumberField source='position'/>
                <ArrayField source='carTypes'>
                    <ShowListOfCarTypes />
                </ArrayField>
            </SimpleShowLayout>
        </Show>
)};
