import queryString from 'query-string';

class CountryEndpointResolver {
    supports(resource) {
        return resource === 'countries';
    }

    getListUri = (_resource, params) => {
        if (params.filter && params.filter.name) {
            params.filter.name = params.filter.name.charAt(0).toUpperCase() + params.filter.name.slice(1);
        }

        const {filter, pagination} = params;
        const queryParams = {
            ...filter,
            page:pagination.page
        }

        return `/countries?${queryString.stringify(queryParams)}`;
    };

    getOneUri(_resource, params) {
        if (params.id.includes('countries')) params.id=params.id.split('/').pop();
        return `/countries/${params.id}`
    }

    getUpdateUri(_resource, params){
        return`/countries/${params.id}`
    }
}
export default CountryEndpointResolver;