import React, { useEffect, useState } from 'react';
import { useDataProvider, Title } from 'react-admin';
import Loader from '../../components/Loader';
import { BRAND_DETAILS } from '../../pages/Brands';

const Profile = () => {
    const dataProvider = useDataProvider();
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [userCountries, setUserCountries] = useState([]);
    const [userDealershipGroups, setUserDealershipGroups] = useState([]);
    const [userBrands, setUserBrands] = useState([]);
    const getUserInfo = async () => {
        try {
            setIsLoading(true);
            const { data } = await dataProvider.getOne('users', { id: localStorage.getItem('sub') });
            setUserDetails(data);

            if (data.avpRoles && data.avpRoles.length > 0) {
                const rolesPromises = data.avpRoles.map(roleId =>
                    dataProvider.getOne('roles', { id: roleId })
                );
                const rolesResponses = await Promise.all(rolesPromises);
                const rolesData = rolesResponses.map(response => response.data);
                setUserRoles(rolesData);
            }

            if(data.affiliatedCountries && data.affiliatedCountries.length >0){
              const countryPromises = data.affiliatedCountries.map(countryId=>
                dataProvider.getOne('countries', {id: countryId})
              );
              const countryResponses = await Promise.all(countryPromises)
              const countryData = countryResponses.map(response => response.data);
              setUserCountries(countryData);
            }

            if(data.dealershipGroups && data.dealershipGroups.length >0){
                const groupPromises = data.dealershipGroups.map(group=>
                  dataProvider.getOne('dealershipGroups', {meta : {id: group}})
                );
                const groupResponses = await Promise.all(groupPromises)
                const groupData = groupResponses.map(response => response.data);

                setUserDealershipGroups(groupData);
            }

            if(data.brands && data.brands.length >0){
                const brandsLetters= data.brands.map(brand => brand.split('/').pop());
                const brands = BRAND_DETAILS.filter(brand => brandsLetters.includes(brand.code))
                const brandsNames = brands.map(brand =>brand.name)
                setUserBrands(brandsNames);
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching user details or roles:', error);
        }
    };

    useEffect(() => {
        getUserInfo();
    }, []);

    if (!userDetails || isLoading) {
        return <Loader/>;
    }

    const countryNames = userCountries.map(country => country.name).join(', ');

    return (
      <div className='profile-container'>
          <Title title='User Profile'/>
          <div className="profile-details">
              <div className="profile-field">
                  <strong>Name:</strong>
                  <span>{userDetails.givenName} {userDetails.familyName}</span>
              </div>
              <div className="profile-field">
                  <strong>Email:</strong>
                  <span>{userDetails.email}</span>
              </div>
              <div className="profile-field">
                  <strong>Brands:</strong>
                  <span>{userBrands.join(', ')}</span>
              </div>
              <div className="profile-field">
                  <strong>Affiliated Countries:</strong>
                  <span>{countryNames}</span>
              </div>
              <div className="profile-field">
                  <strong>Channels:</strong>
                  <span>{userDetails.channels.map(channel => channel.split('/').pop()).join(', ')}</span>
              </div>
              <div className="profile-field">
                  <strong>Roles:</strong>
                  <span>{userRoles.map(role => role.name).join(', ')}</span>
              </div>
              <div className="profile-field">
                  <strong>DealershipGroups:</strong>
                  <span>{userDealershipGroups.map(group => group.title).join(', ')}</span>
              </div>
              <div className="profile-field">
                  <strong>Show Tutorial:</strong>
                  <span>{userDetails.showTutorial ? 'Yes' : 'No'}</span>
              </div>
              <div className="profile-field">
                  <strong>Enabled:</strong>
                  <span>{userDetails.enabled ? 'Yes' : 'No'}</span>
              </div>
          </div>
      </div>
  );
};

export default Profile;
