
import React from 'react';
import { Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Dashboard = () => (
    <Card sx={{marginTop: '5px'}}>
        <Title title='Admin Retail Content Manager' />
        <CardContent>
            <h1>Welcome</h1>
            <p>The Admin Retail Content Manager provides a GUI to manage administrative data on the NewRetail API. (e.g. Dealership Groups, Devices, Dealerships)</p>
            <p>For issues and support, please contact us via our <a style={{color: '#000'}} target="_blank" rel="noreferrer" href='https://collaboration.msi.audi.com/jira/plugins/servlet/theme/portal/51'>AVP Support</a>.</p>
        </CardContent>
    </Card>
);

export default Dashboard;