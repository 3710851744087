class ContentPlaylistEndpointResolver {
    supports(resource) {
        return resource === 'contentPlaylists';
    }


    getOneUri(_resource, params) {
        const brand = params.filter.brand;
        const slug = params.filter.slug;
        const uuid = params.filter.uuid;

        if (!brand || !slug || !uuid){
            console.error('brand, slug or uuid are missing for contentPlaylist');
            throw new Error('brand, slug or uuid are missing for contentPlaylist')
        }

        return `/brands/${brand}/channels/${slug}/content-playlists/${uuid}`

    }
}

export default ContentPlaylistEndpointResolver;