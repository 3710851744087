import React from 'react';
import {
    List,
    Datagrid,
    TextInput,
    TopToolbar,
    SimpleForm,
    CreateButton,
    Create,
    SelectInput,
    FunctionField
} from 'react-admin';
import { SplashScreenField } from '../fields/SplashScreenField';
import './styles/Custom.css'

export const BRAND_DETAILS = [
    { 'code':'A', 'name':'Audi' },
    { 'code':'S', 'name':'SEAT CUPRA' },
    { 'code':'V', 'name':'Volkswagen' },
    { 'code':'N', 'name':'VW Nutzfahrzeuge' },
    // { 'code':'C', 'name':'Škoda' },
    // { 'code':'E', 'name':'Bentley' },
    // { 'code':'G', 'name':'Bugatti' },
    // { 'code':'L', 'name':'Lamborghini' },
    // { 'code':'M', 'name':'M.A.N' },
];

const BrandListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export const BrandList = () => {
    const postRowStyle = (record) => ({ display: record.code === 'N' && 'none'});
    return (
        <List pagination = {null} actions={<BrandListActions />} >
            <Datagrid rowSx={postRowStyle} filters='false' bulkActionButtons={false}>
                <FunctionField
                    label="Brand"
                    render={record => {
                        const brand = BRAND_DETAILS.find(b => b.code === record.code);
                        return brand ? brand.name : record.code
                    }}
                />
                <SplashScreenField source='splashScreen' />
            </Datagrid>
        </List>
    );
};

export const BrandCreate = () => {
    const choices = BRAND_DETAILS.map(brand => ({ id: brand.code, name: brand.name }));
    return (
        <Create>
            <SimpleForm>
                <SelectInput source='code' label ='Brand Code' choices={choices} fullWidth/>
                <TextInput source='name' fullWidth label= 'Brand Name' />
                <TextInput source='splashScreen' fullWidth label= 'Splash Screen Image URL' multiline />
            </SimpleForm>
        </Create>
)};