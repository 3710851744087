import React, {useEffect, useState, useCallback} from 'react';
import {
    useDataProvider,
    TopToolbar,
    CreateButton,
    Create,
    SimpleForm,
    TextInput,
    FormDataConsumer,
    Toolbar,
    useSaveContext,
    useRedirect,
    useNotify,
    Edit,
    EditButton,
    ShowButton,
    SimpleShowLayout,
    Show,
    TextField,
    Button,
    useRefresh,
    Confirm,
    required,
    useShowController,
    useListController,
    List,
    Datagrid,
    SearchInput,
    ArrayField,
    FunctionField,
} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../components/Loader';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '../assets/icons/DeleteIcon';
import Pagination from '../components/Pagination';
import { debounce } from 'lodash';
import { CustomBrandField } from '../fields/CustomBrandField';
import Chip from '@mui/material/Chip';

const DealershipGroupsListActions = () => (
        <TopToolbar>
            <CreateButton label = 'Create' />
        </TopToolbar>
)

const dealersGroupFilters = [
        <SearchInput key='kvps' source='kvps' alwaysOn placeholder='By KVPS' style={{ padding:'1rem 0' }} debounce='true' resettable/>,
        <SearchInput key='title' source='title' alwaysOn placeholder='By Title' style={{ padding:'1rem 0' }} debounce='true' resettable/>,
        <SearchInput key='country' source='country' alwaysOn placeholder='By Country ISO3' style={{ padding:'1rem 0' }} debounce='true' resettable/>
];

export const DealershipGroupsList = () => {
    const {page, total, setPage } = useListController();
    return (
            <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<DealershipGroupsListActions/>} filters={dealersGroupFilters}>
                <Datagrid bulkActionButtons={false}>
                    <CustomBrandField source="brand" />
                    <TextField source='title' />
                    <ArrayField source='dealerships'>
                        <FunctionField
                            label="dealerships"
                            render={record => record.dealerships.map((dealer,i) =>  <Chip key={i} label={dealer.split('/').pop()} sx={{margin: '4px'}} />)}
                        />                    
                    </ArrayField>
                    <ArrayField source='countries'>
                        <FunctionField
                            label="countries"
                            render={record => record.countries.map((country,i) => <Chip key={i} label={country.split('/').pop()} sx={{margin: '4px'}} />)}
                        />                    
                    </ArrayField>
                    <span className='crud_buttons'>
                        <EditButton />
                        <ShowButton />
                    </span>
                </Datagrid>
            </List>
          )
}

export const DealershipGroupsCreate = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [searchUserResults, setSearchUserResults] = useState('');
    const [searchCountryResults, setSearchCountryResults] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedDealerships, setSelectedDealerships] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [searchUserQuery, setSearchUserQuery] = useState('');
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [searchDealershipResults, setSearchDealershipResults] = useState('');
    const [searchDealershipQuery, setSearchDealershipQuery] = useState('');
    const [isSearchingDealership, setIsSearchingDealership] = useState(false);
    const [isSearchingCountry, setIsSearchingCountry] = useState(false);
    const [isSearchingUser, setIsSearchingUser] = useState(false);

    const searchDealership = async (query) => {
        if (query.length > 2){
            try {
                setIsSearchingDealership(true)
                const response = await dataProvider.getList('dealerships',{
                    filter:{kvps: query},
                    pagination:{page:1}
                });

                setSearchDealershipResults(response.data)
                setIsSearchingDealership(false)

            }catch(error){
                console.error('Error fetching dealerships');
                setIsSearchingDealership(false)
            }
        }else {
            setSearchDealershipResults('');
        }
    }

    const handleSearchDealershipChange = (event) => {
        const query = event.target.value
        setSearchDealershipQuery(query);
        debouncedSearchDealership(query)
    };

    useEffect(() => {
        searchDealership('');
        searchUser('');
        searchCountry('');
    }, []);

    const debouncedSearchDealership = useCallback(
        debounce((query) => {
            searchDealership(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealership.cancel();
        };
    }, [debouncedSearchDealership]);

    const debouncedSearchUser = useCallback(
        debounce((query) => {
            searchUser(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchUser.cancel();
        };
    }, [debouncedSearchUser]);

    const debouncedSearchCountry = useCallback(
        debounce((query) => {
            searchCountry(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchCountry.cancel();
        };
    }, [debouncedSearchCountry]);

    const searchUser = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingUser(true)
                const response = await dataProvider.getList('users', {
                    filter: { email: query },
                    pagination: { page: 1 },
                });

                setSearchUserResults(response.data)

                setIsSearchingUser(false)
            } catch (error) {
                console.error('Error fetching users:', error);
                setIsSearchingUser(false)
            }
        } else {
            setSearchUserResults('');
        }
    };

    const CustomToolbar = () => {
        const { save } = useSaveContext();

        const handleSaveClick = (event) => {
            event.preventDefault(); // Prevent default form submission
            save(); // Use the custom save function
        };

        return (
            <Toolbar>
                <button style={{display:'none'}} className='save_button' onClick={handleSaveClick} type="button">
                    <SaveIcon fontSize='small'/>
                    <div className='save_text'>SAVE</div>
                </button>
            </Toolbar>
        );
    };

    const renderUsers = () => {
        return (
            <div>
                {selectedUsers.map((user, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                        {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleUserRemove(user)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleUserRemove = (userToRemove) => {
        setSelectedUsers(prevUsers =>
            prevUsers.filter(user => user.id !== userToRemove.id)
        );
    };

    const handleDealershipRemove = (dealershipToRemove) => {
        setSelectedDealerships(prevDealerships =>
            prevDealerships.filter(dealership => dealership.id !== dealershipToRemove.id)
        );
    };

    const handleSearchUserChange = (event) => {
        const query = event.target.value
        setSearchUserQuery(query);
        debouncedSearchUser(query)
    };

    const handleCountryRemove = (countryToRemove) => {
        setSelectedCountries(prevCountries =>
            prevCountries.filter(country => country.id !== countryToRemove.id)
        );
    };

    const renderCountries = () => {
        return (
            <div>
                {selectedCountries.map((country, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {country.name}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleCountryRemove(country)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleUserSelect = (user) => {
        setSelectedUsers(prev => {
            const isDuplicate = prev.some(existingUser => existingUser.id === user.id);
            return isDuplicate ? prev : [...prev, user];
        });
        setSearchUserResults('');
    };

    const handleCountrySelect = (country) => {
        setSelectedCountries(prev => {
            const isDuplicate = prev.some(existingCountry => existingCountry.id === country.id);
            return isDuplicate ? prev : [...prev, country];
        });
        setSearchCountryResults('');
    };

    const handleSearchCountryChange = (event) => {
        const query = event.target.value
        setSearchCountryQuery(query);
        debouncedSearchCountry(query)

    };

    const searchCountry = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingCountry(true)
                const response = await dataProvider.getList('countries', {
                    filter: { name: query },
                    pagination: { page: 1 },
                });

                setSearchCountryResults(response.data)
                setIsSearchingCountry(false)
            } catch (error) {
                console.error('Error fetching countries:', error);
                setIsSearchingCountry(false)
            }
        } else {
            setSearchCountryResults('');
        }
    };

    const handleDealershipSelect = (dealership) => {
        setSelectedDealerships(prev => {
            const isDuplicate = prev.some(existingDealership => existingDealership.id === dealership.id);
            return isDuplicate ? prev : [...prev, dealership];
        });
        setSearchDealershipResults('');
    };

    const renderDealerships = () => {
        return (
            <div>
                {selectedDealerships.map((dealership, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {dealership.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleDealershipRemove(dealership)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Create>
                <SimpleForm toolbar = {<CustomToolbar/>}>
                    <TextInput fullWidth source='title' helperText='Brief description of the dealership group.' validate={[required()]}/>
                    {/* search countries */}
                    <InputLabel className='input_label'>Countries:</InputLabel>
                    <div className='countries'>
                        {renderCountries()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='By Country Name'
                                value = {searchCountryQuery}
                                onChange={handleSearchCountryChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        <p className='hint'>Select the countries where the dealerships in this group operate. Selecting a country will make all dealerships in that country manageable by the members of the dealership group.</p>
                        {Array.isArray(searchCountryResults) && !isSearchingCountry &&
                            (searchCountryResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchCountryResults.map(country => (
                            <div className = 'search-result-options' key={country.id} onClick={() => handleCountrySelect(country)}>
                                {country.name}
                            </div>
                        )))}
                        {isSearchingCountry && <div>Searching...</div>}
                    </div>
                    <InputLabel className='input_label'>Custom dealerships:</InputLabel>
                    <div className='countries'>
                        {renderDealerships()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='By Dealership KVPS'
                                value = {searchDealershipQuery}
                                onChange={handleSearchDealershipChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        <p className='hint'>Search and add specific dealerships codes.</p>
                        {Array.isArray(searchDealershipResults) && !isSearchingDealership &&
                            (searchDealershipResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchDealershipResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        )))}
                        {isSearchingDealership && <div>Searching...</div>}
                    </div>

                    {/* search users */}
                    <InputLabel className='input_label'>Users:</InputLabel>
                    <div className='countries'>
                        {renderUsers()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='By User Email'
                                value = {searchUserQuery}
                                onChange={handleSearchUserChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        <p className='hint'>Search and add users by their email addresses who will manage this group.</p>
                        {Array.isArray(searchUserResults) && !isSearchingUser &&
                            (searchUserResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchUserResults.map(user => (
                            <div className='search-result-options' key={user.id} onClick={() => handleUserSelect(user)}>
                                {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                            </div>
                        )))}
                        {isSearchingUser && <div>Searching...</div>}
                    </div>
                    <FormDataConsumer>
                        {({ formData, }) => {
                            const handleCustomSave = async () => {
                                formData.users = selectedUsers.map(user=>user['@id'])
                                formData.countries = selectedCountries.map(country => country['@id']);
                                formData.dealerships = selectedDealerships.map(dealership => dealership['@id']);
                                try {
                                    if(!formData.title) { throw new Error('The title field is mandatory and must be filled out.') }
                                    await dataProvider.create('dealershipGroups', { data: formData})
                                    notify('Dealership group created successfully', 'info');
                                    redirect('/dealershipGroups');
                                } catch (error) {
                                    console.error('Error creating dealership group:', error);
                                    notify(`Error creating dealership group: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative', top:'70px', zIndex:'99'}}className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>

                </SimpleForm>
        </Create>
   )
}

export const DealershipGroupsEdit = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { record } = useShowController();

    const [searchUserResults, setSearchUserResults] = useState('');
    const [searchDealershipResults, setSearchDealershipResults] = useState('');
    const [searchCountryResults, setSearchCountryResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedDealerships, setSelectedDealerships] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [searchUserQuery, setSearchUserQuery] = useState('');
    const [searchDealershipQuery, setSearchDealershipQuery] = useState('');
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [isSearchingCountry, setIsSearchingCountry] = useState(false);
    const [isSearchingUser, setIsSearchingUser] = useState(false);
    const [isSearchingDealership, setIsSearchingDealership] = useState(false);
    const [isFetchingAdditionalDetails, setIsFetchingAdditionalDetails] = useState(false);

    useEffect(() => {
        if(record){
            const fetchAdditionalDetails = async () => {
                setIsFetchingAdditionalDetails(true);
                const fetchedUsers = await Promise.all(record.users.map(userId => dataProvider.getOne('users', { id: userId.split('/').pop().trim() })));
                setSelectedUsers(fetchedUsers.map(response => response.data));
                const fetchedCountries = await Promise.all(record.countries.map(countryId => dataProvider.getOne('countries', { id: countryId.split('/').pop().trim() })));
                setSelectedCountries(fetchedCountries.map(response => response.data));
                const fetchedDealerships = await Promise.all(record.dealerships.map(dealershipId => dataProvider.getOne('dealerships', {id: dealershipId.split('/').pop()})));
                setSelectedDealerships(fetchedDealerships.map(response => response.data));
                setIsFetchingAdditionalDetails(false);
            };
            fetchAdditionalDetails();
        }
    }, [record]);


    useEffect(() => {
        searchDealership('');
        searchUser('');
        searchCountry('');
    }, [record]);

    const debouncedSearchDealership = useCallback(
        debounce((query) => {
            searchDealership(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealership.cancel();
        };
    }, [debouncedSearchDealership]);

    const debouncedSearchCountry = useCallback(
        debounce((query) => {
            searchCountry(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchCountry.cancel();
        };
    }, [debouncedSearchCountry]);

    const debouncedSearchUser = useCallback(
        debounce((query) => {
            searchUser(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchUser.cancel();
        };
    }, [debouncedSearchUser]);

    const searchUser = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingUser(true)
                const response = await dataProvider.getList('users', {
                    filter: { email: query },
                    pagination: { page: 1 }
                });

                setSearchUserResults(response.data)
                setIsSearchingUser(false)
            } catch (error) {
                console.error('Error fetching users:', error);
                setIsSearchingUser(false)
            }
        } else {
            setSearchUserResults('');
        }
    };

    const searchDealership = async (query) => {
        if (query.length > 2){
            try {
                setIsSearchingDealership(true)
                const response = await dataProvider.getList('dealerships',{
                    filter:{kvps: query},
                    pagination:{page:1},
                });

                setSearchDealershipResults(response.data)
                setIsSearchingDealership(false)
            }catch(error){
                console.error('Error fetching dealerships', error);
                setIsSearchingDealership(false)
            }
        }else {
            setSearchDealershipResults('');
        }
    }

    const CustomDeleteButton = ({record}) => {
        const [open, setOpen] = useState(false);
        const dataProvider = useDataProvider();
        const notify = useNotify();
        const refresh = useRefresh();
        const redirect = useRedirect();
    
        const handleDelete = async () => {
            setOpen(false);
            try {
                await dataProvider.delete('dealershipGroups', { id: record.id, brandCode: record.brand.split('/').pop() });
                refresh();
                notify('Resource deleted successfully', 'info');
                redirect('/dealershipGroups');
            } catch (error) {
                notify(`Error deleting resource: ${error.message}`, 'warning');
            }
        };
        return (
            <>
                <Button label='Delete' onClick={() => setOpen(true)} style = {{marginLeft:'10px'}}>
                    <DeleteIcon />
                </Button>
                <Confirm
                    isOpen={open}
                    loading={false}
                    title='Are you sure you want to delete this group?'
                    content='Only the person that has created a dealership group can delete it. Even then, the dealership group must first be emptied out.'
                    onConfirm={handleDelete}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    };

    const CustomToolbar = () => {
        const { save } = useSaveContext();
        const handleSaveClick = (event) => {
            event.preventDefault();
            save();
        };

        return (
            <Toolbar style={{display:'flex', justifyContent:'flex-end'}}>
                <button style={{display:'none'}} className='save_button' onClick={handleSaveClick} type="button">
                    <SaveIcon fontSize='small'/>
                    <div className='save_text'>SAVE</div>
                </button>
                <CustomDeleteButton  record={record} />
            </Toolbar>
        );
    };

    const renderUsers = () =>
        selectedUsers.length === 0
        ? <div>No users</div>
        : <div>
                {selectedUsers.map((user, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                        {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleUserRemove(user)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    const renderDealerships = () => 
        selectedDealerships.length === 0
            ?<div>No dealers</div>
            :<div>
                {selectedDealerships.map((dealership, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {dealership.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleDealershipRemove(dealership)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    const handleDealershipRemove = (dealershipToRemove) => {
        setSelectedDealerships(prevDealerships =>
            prevDealerships.filter(dealership => dealership.id !== dealershipToRemove.id)
        );
    };

    const handleUserRemove = (userToRemove) => {
        setSelectedUsers(prevUsers =>
            prevUsers.filter(user => user.id !== userToRemove.id)
        );
    };

    const handleSearchUserChange = (event) => {
        const query = event.target.value
        setSearchUserQuery(query);
        debouncedSearchUser(query)
    };

    const handleSearchDealershipChange = (event) => {
        const query = event.target.value
        setSearchDealershipQuery(query);
        debouncedSearchDealership(query)
    };

    const handleCountryRemove = (countryToRemove) => {
        setSelectedCountries(prevCountries =>
            prevCountries.filter(country => country.id !== countryToRemove.id)
        );
    };

    const renderCountries = () => 
        selectedCountries.length === 0
        ? <div>No countries</div>
        : <div>
                {selectedCountries.map((country, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {country.name}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleCountryRemove(country)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    const handleUserSelect = (user) => {
        setSelectedUsers(prev => {
            const isDuplicate = prev.some(existingUser => existingUser.id === user.id);
            return isDuplicate ? prev : [...prev, user];
        });
        setSearchUserResults('');
    };

    const handleDealershipSelect = (dealership) => {
        setSelectedDealerships(prev => {
            const isDuplicate = prev.some(existingDealership => existingDealership.id === dealership.id);
            return isDuplicate ? prev : [...prev, dealership];
        });
        setSearchDealershipResults('');
    };

    const handleCountrySelect = (country) => {
        setSelectedCountries(prev => {
            const isDuplicate = prev.some(existingCountry => existingCountry.id === country.id);
            return isDuplicate ? prev : [...prev, country];
        });
        setSearchCountryResults('');
    };

    const handleSearchCountryChange = (event) => {
        const query = event.target.value
        setSearchCountryQuery(query);
        debouncedSearchCountry(query)
    };

    const searchCountry = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingCountry(true)
                const response = await dataProvider.getList('countries', {
                    filter: { name: query },
                    pagination: { page: 1 },
                    sort: { field: 'iso3', order: 'ASC' }
                });

                setSearchCountryResults(response.data)
                setIsSearchingCountry(false)
            } catch (error) {
                console.error('Error fetching countries:', error);
                setIsSearchingCountry(false)
            }
        } else {
            setSearchCountryResults('');
        }
    };

    if (!record || isFetchingAdditionalDetails) return <Loader/>

    return (
        <Edit title={record.title}>
                <SimpleForm toolbar = {<CustomToolbar/>}>
                <TextInput fullWidth source='title' helperText='Brief description of the dealership group.' validate={[required()]}/>
                {/* search countries */}
                    <InputLabel className='input_label'>Countries:</InputLabel>
                    <div className='countries'>
                        {renderCountries()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='By Country Name'
                                value = {searchCountryQuery}
                                onChange={handleSearchCountryChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        <p className='hint'>Select the countries where the dealerships in this group operate. Selecting a country will make all dealerships in that country manageable by the members of the dealership group.</p>
                        {Array.isArray(searchCountryResults) && !isSearchingCountry &&
                            (searchCountryResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchCountryResults.map(country => (
                            <div className = 'search-result-options' key={country.id} onClick={() => handleCountrySelect(country)}>
                                {country.name}
                            </div>
                        )))}
                        {isSearchingCountry && <div>Searching...</div>}
                    </div>
                    <InputLabel className='input_label'>Custom dealerships:</InputLabel>
                    <div className='countries'>
                        {renderDealerships()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='By Dealership KVPS'
                                value = {searchDealershipQuery}
                                onChange={handleSearchDealershipChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        <p className='hint'>Search and add specific dealerships codes.</p>
                        {Array.isArray(searchDealershipResults) && !isSearchingDealership &&
                            (searchDealershipResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchDealershipResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        )))}
                        {isSearchingDealership && <div>Searching...</div>}
                    </div>
                    <InputLabel className='input_label'>Users:</InputLabel>
                    <div className='countries'>
                        {renderUsers()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='By User Email'
                                value = {searchUserQuery}
                                onChange={handleSearchUserChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        <p className='hint'>Search and add users by their email addresses who will manage this group.</p>
                        {Array.isArray(searchUserResults) && !isSearchingUser &&
                            (searchUserResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchUserResults.map(user => (
                            <div className='search-result-options' key={user.id} onClick={() => handleUserSelect(user)}>
                                {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                            </div>
                        )))}
                        {isSearchingUser && <div>Searching...</div>}
                    </div>
                    <FormDataConsumer>
                        {({ formData, }) => {
                            const handleCustomSave = async () => {
                                let dataToSet = {};
                                const previousData = JSON.parse(JSON.stringify(formData));
                                dataToSet.title = formData.title;
                                dataToSet.users = selectedUsers.map(user=>user['@id']);
                                dataToSet.countries = selectedCountries.map(country => country['@id']);
                                dataToSet.dealerships = selectedDealerships.map(dealership => dealership['@id']);

                                try {
                                    await dataProvider.update('dealershipGroups', { id:record['@id'], data: dataToSet, previousData: previousData})
                                    notify('Dealership group updated successfully', 'info');
                                    redirect(`/dealershipGroups/${record.id}/show`);
                                } catch (error) {
                                    console.error('Error updating dealership group:', error);
                                    notify(`Error updating dealership group: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative', top:'70px', zIndex:'99'}} className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>

                </SimpleForm>
        </Edit>
   )
}

const DealershipsTitles = ({ dealershipsMap }) => (
    dealershipsMap.length > 0 ?
        <div className='info'>
            {dealershipsMap.map((dealership, index) => (
                <div key={index}>{dealership.title}</div>
            ))}
        </div>
         :
         <div className='info'>No custom dealerships</div>
    );

const CountriesTitles = ({ countriesMap }) => (
        countriesMap.length > 0 ?
            <div className='info'>
                {countriesMap.map((country, index) => (
                    <div key={index}>{country.name}</div>
                ))}
            </div>
         :
         <div className='info'>No countries</div>
    );

const UsersTitles = ({ usersMap }) =>( 
    usersMap.length > 0 ?
            <div className='info'>
                {usersMap.map((user, index) => (
                    <div key={index}>{user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}</div>
                ))}
            </div>
        :
        <div className='info'>No users</div>
    );


export const DealershipGroupsShow = () => {
    const { record } = useShowController();
    const dataProvider = useDataProvider();

    const [isFetchingAdditionalDetails, setIsFetchingAdditionalDetails] = useState(false);
    const [showUsers, setShowUsers] = useState([]);
    const [showDealerships, setShowDealerships] = useState([]);
    const [showCountries, setShowCountries] = useState([]);

    useEffect(() => {
        if (record) {
            const fetchAdditionalDetails = async () => {
                setIsFetchingAdditionalDetails(true);
                const fetchedUsers = await Promise.all(record.users.map(userId => dataProvider.getOne('users', { id: userId.split('/').pop().trim() })));
                setShowUsers(fetchedUsers.map(response => response.data));
                const fetchedCountries = await Promise.all(record.countries.map(countryId => dataProvider.getOne('countries', { id: countryId.split('/').pop().trim() })));
                setShowCountries(fetchedCountries.map(response => response.data));
                const fetchedDealerships = await Promise.all(record.dealerships.map(dealershipId => dataProvider.getOne('dealerships', {id: dealershipId.split('/').pop()})));
                setShowDealerships(fetchedDealerships.map(response => response.data));
                setIsFetchingAdditionalDetails(false);
            };
            fetchAdditionalDetails();
        }
    }, [record]);

    if(!record || isFetchingAdditionalDetails) return <Loader/>;

    return (
        <Show title={record.title}>
            <SimpleShowLayout>
                <TextField source='title'/>
                <CustomBrandField source="brand" />
                <TextField source='Countries:'/>
                <CountriesTitles
                    countriesMap={showCountries}
                />
                <TextField source='Custom dealerships:'/>
                <DealershipsTitles
                    dealershipsMap={showDealerships}
                />
                <TextField source='Users:'/>
                <UsersTitles
                    usersMap={showUsers}
                />
            </SimpleShowLayout>
        </Show>
    )
}


export const DealershipGroupsDelete = ({ record }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleDelete = async () => {
        try {
            await dataProvider.delete('dealershipGroups', { id: record.id });
            refresh()
            notify('Dealership group deleted successfully', 'info');
            redirect('/dealershipsGroups');
        } catch (error) {
            notify(`Error deleting dealership group: ${error.message}`, 'warning');
            console.error('Error deleting dealership group:', error);
        }
    };
    return <Button onClick={handleDelete}>Delete</Button>;
};