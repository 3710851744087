import React from 'react';
import {
    TopToolbar,
    ShowButton,
    SimpleShowLayout,
    TextField,
    Show,
    List,
    Datagrid,
    useListController
} from 'react-admin';
import Pagination from '../components/Pagination';

const AssetOverridesListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

export const AssetOverridesList = () => { 
    const {page, total, setPage } = useListController();
    return (
        <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<AssetOverridesListActions/>}>
            <Datagrid bulkActionButtons={false}>
                <TextField source='type'/>
                <TextField source='deviceNumber'/>
                <TextField 
                source='url' 
                style={{width: '50vw',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block'
                        }}
                />
                <ShowButton className='show-button'/>
            </Datagrid>
        </List>
)}
    
export const AssetOverridesShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source='type' />
                <TextField source='deviceNumber' />
                <TextField source='url' />
                <TextField source='state' />
            </SimpleShowLayout>
        </Show>
    )
}

