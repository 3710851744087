import {
    TextInput,
    EditButton,
    Edit,
    Create,
    CreateButton,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    SimpleShowLayout,
    TextField,
    Show,
    ShowButton,
    useRecordContext,
    TopToolbar,
    List,
    Datagrid,
    useShowController,
    useListController
} from 'react-admin';
import React from 'react';
import './styles/Custom.css';
import { CustomBrandField } from '../fields/CustomBrandField';
import Loader from '../components/Loader.js';
import Pagination from '../components/Pagination';

const ModuleTypeListActions = () => (
  <TopToolbar>
      <CreateButton label='Create' />
  </TopToolbar>
);

const CountryNamesField = ({ source }) => {
  const record = useRecordContext();
  const countryRestrictions = record[source];
  if (countryRestrictions.length === 0) {
    return <div>No country restrictions</div>;
  }
  return (
    <div>
      {countryRestrictions.join(', ')}
    </div>
  );
};

export const ModuleTypeList = () =>{ 
  const {page, total, setPage } = useListController();
  return (
      <List pagination={<Pagination total={total} current={page} onChange={setPage} />} actions={<ModuleTypeListActions/>}>
        <Datagrid bulkActionButtons={false}>
            <TextField source='code'/>
            <CustomBrandField source="brand" />
            <TextField source='title'/>
            <TextField source='category'/>
            <CountryNamesField source="countryRestrictions"/>
            <span className='crud_buttons'>
                <EditButton className='edit-button'/>
                <ShowButton className='show-button'/>
            </span>
        </Datagrid>
    </List>)}

export const ModuleTypeShow = () => {
  const { record } = useShowController();
  if (!record) return <Loader/>;
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source='code' />
        <CustomBrandField source="brand" />
        <TextField source='title' />
        <TextField source='category'/>
        <CountryNamesField source="countryRestrictions"/>
      </SimpleShowLayout>
    </Show>
)};

export const ModuleTypeEdit = () => {
  const { record } = useShowController();
  if (!record) return <Loader/>;
  return (
    <Edit redirect='show'>
      <SimpleForm>
        <TextInput fullWidth source='title' />
        <TextInput fullWidth source='category'/>
        <ArrayInput fullWidth source='countryRestrictions' label='List of Country Restrictions (Add iso3 code, for example: DEU)'>
          <SimpleFormIterator>
              <TextInput fullWidth/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const ModuleTypeCreate = () => (
    <Create redirect='show'>
      <SimpleForm>
        <TextInput fullWidth source='code' />
        <TextInput fullWidth source='title' />
        <TextInput fullWidth source='category'/>
        <ArrayInput fullWidth source='countryRestrictions' label='List of Country Restrictions (Add iso3 code, for example: DEU)'>
          <SimpleFormIterator>
              <TextInput fullWidth label='Must not be empty'/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );