import React, { useEffect } from 'react';
import { auth } from './GRP';
import { setCookie } from './helpers';
import Loader from './components/Loader';
import { useNavigate } from 'react-router-dom';
import { AvpContextClaims } from '@oneaudi/avp-context-claims';

const ConnectView = () => {
    const navigate = useNavigate();

    const grpAuthorize = async () => {
        try{
            const user = await auth.getIdTokenPayload();
            if (!user) {
                await auth.authorize();
            }
            const token = await auth.getToken();

            if (token && token.accessToken) {
                const currentTimeEpocSec = Date.now() / 1000;
                const token = await auth.getToken();
                const idToken = await auth.getIdTokenPayload();

                const duration = token.exp = currentTimeEpocSec;
                const hours = duration / 3600;

                localStorage.setItem('sub', idToken.sub);
                localStorage.setItem('roles', AvpContextClaims.getGroups(idToken));

                if (AvpContextClaims.isDealerOnly(idToken)){
                    throw new Error('DEALER role only')
                }

                const userBrands = AvpContextClaims.getBrands(idToken);
                localStorage.setItem('userBrands', userBrands);
                localStorage.setItem('selectedBrand', userBrands[0]);

                let link = document.querySelector('link[rel~=icon]');
                if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = `/${userBrands[0]}-favicon.ico`;

                setCookie('token', token.accessToken, hours);
                setCookie('refreshToken', token.refreshToken, hours + 4);
                navigate('/');
            } else {
                console.log('waiting for the user token')
            }
        }catch(error){
            console.error('Error in authorization process:', error);
            if (error.message === 'DEALER role only'){
                navigate('/login', { state: { error: 'DEALER role only' } });
            }
        }
    }

    useEffect(() => {
        grpAuthorize();
    },[]);

    return (
        <div className='login_div'>
            <Loader/>
        </div>
    );
};

export default ConnectView;