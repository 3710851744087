import {store} from '../../store';

class FaqEndpointResolver {
    supports(resource) {
        return resource === 'faqs';
    }

    getOneUri = (_resource, params) => {
        return params.id
    }

    getListUri = () => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/channels/${state.brand.brand.toLowerCase()}-digital-retail/faqs`;
    };

    getUpdateUri = (_resourcae, params) => {
        return params.id;
    }

    getCreateUri = () => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/channels/${state.brand.brand.toLowerCase()}-digital-retail/faqs`;
    }

    getDeleteUri = (_resource, params) => {
        return params.id;
    }
}

export default FaqEndpointResolver;
