import React from 'react';
import { setBrand } from '../actions/brandSwitcherActions';
import {useDispatch} from 'react-redux';
import { useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useRefresh } from 'react-admin';

const BrandSwitcher = ({ brands }) => {
    const dispatch = useDispatch();
    const selectedBrand = useSelector(state => state.brand.brand);
    const refresh = useRefresh();

    const handleBrandChange = (event) => {
        const selectedBrand = event.target.value;
        let link = document.querySelector('link[rel~=icon]');
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `/${selectedBrand}-favicon.ico`;
        localStorage.setItem('selectedBrand', selectedBrand);
        dispatch(setBrand(selectedBrand));
        refresh()
    };
    return (
            <Select
                value={selectedBrand}
                onChange={handleBrandChange}
                style={{backgroundColor:'white', height:'25px', marginLeft:'8px'}}
            >
                {brands.map(brand => (
                    <MenuItem key={brand.code} value={brand.code}>
                        {brand.name}
                    </MenuItem>
                ))}

            </Select>
    );
};

export default BrandSwitcher;
